<template>
  <div class="auth-content align-center">
    <img src="~assets/images/auth/auth-top-bg.png" class="auth-top-bg" alt="" />
    <img src="~assets/images/auth/auth-bot-bg.png" class="auth-bot-bg" alt="" />
    <div class="auth-wrap">
      <img src="~assets/images/auth/auth-bg.png" class="auth-bg" alt="" />
      <img src="~assets/images/auth/auth-logo.png" class="auth-logo" @click="linkHome" alt="" />
      <p class="auth-hint-p">医生为本，取之于医，用之于医</p>
      <!--  输入内容  -->
      <div class="auth-inp-box">
        <p class="auth-inp-tit">找回密码</p>
        <!--  手机号图片验证码验证    -->
        <div class="auth-inp-cont" v-if="code_img_status">
          <div class="auth-inp-item vertical-center">
            <img src="~assets/images/auth/auth-mobile-icon.png" class="inp-icon" alt="" />
            <el-input type="text" placeholder="请输入手机号" v-model.trim="mobile" maxlength="11" class="auth-inp" />
            <p class="error-hint-p" v-if="mobile_status_error">请输入正确的手机号</p>
          </div>
          <div class="auth-inp-item code-auth-inp-item vertical-center">
            <img src="~assets/images/auth/auth-code-icon.png" class="inp-icon" alt="" style="width: 16px;" />
            <el-input type="text" v-model.trim="captcha_code" placeholder="请输入验证码" maxlength="6" class="auth-inp" />
            <div class="image-code-box vertical-center align-center">
              <div class="img-code" @click="reloadCode"></div>
              <img :src="codeImgInfo.captcha_image_content" class="show-img-code" alt="" />
            </div>
            <p class="error-hint-p" v-if="code_img_status_error">请输入验证码</p>
          </div>

          <div class="login-btn" style="margin-top: 106px" @click="codeImgSubmit">提交</div>

          <div class="login-link">已有账号，<span class="login-link-btn" @click="loginLink">快捷登录</span></div>
        </div>
        <!--  密码输入信息结束    -->

        <!--  手机号验证码，密码提交    -->
        <div class="auth-inp-cont" v-else>
          <div class="auth-inp-item code-auth-inp-item vertical-center">
            <img src="~assets/images/auth/auth-code-icon.png" class="inp-icon" alt="" />
            <el-input type="text" placeholder="请输入短信验证码" maxlength="6" v-model.trim="verification_code" class="auth-inp" />
            <div class="code-box" @click="getCodeText" v-if="time_status">获取验证码</div>
            <div class="code-box" v-else>{{ time }}秒后重新获取</div>
            <p class="error-hint-p" v-if="verification_code_status">请输入获取验证码</p>
          </div>
          <div class="auth-inp-item vertical-center">
            <img src="~assets/images/auth/auth-mobile-icon.png" class="inp-icon" alt="" />
            <el-input :type="password_show_status ? 'text' : 'password'" placeholder="请输入密码" maxlength="20" v-model.trim="password" class="auth-inp" />
            <!-- <p class="error-hint-p" v-if="password_status">请输入密码</p> -->
            <div class="error-hint-p msg_p">
              <img src="~assets/images/auth/warning.png" mode="scaleToFill" /><span>
                请输入密码8-20位，大小写字母、数字、符号至少三种
              </span>
            </div>
            <div class="pad-show-icon" @click="padShowStatus" v-if="password_show_status"></div>
            <div class="pad-hide-icon" @click="padShowStatus" v-else></div>
          </div>

          <div class="login-btn register-btn" v-if="register_submit_status" @click="registerBtn">提交</div>
          <div class="login-btn register-btn" v-else>提交中...</div>

          <div class="edit-btn" @click="backMobile">返回修改手机号</div>
        </div>
        <!--  密码输入信息结束    -->
      </div>
    </div>
    <div class="bottom-hint-box align-center">
      {{ $static.footer_text }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: 'Forget',
  data() {
    return {
      code_img_status: true, // 图片验证码
      codeImgInfo: {}, // 图片验证码信息
      mobile: '', // 手机号
      captcha_code: '', // 图片验证码
      mobile_status_error: false,
      code_img_status_error: false,
      codeTextInfo: {}, // 文字验证码发送
      verification_code: '', // 验证码
      verification_code_status: false,
      password_status: false,
      password: '', // 密码
      timer: null,
      time: 120,
      time_status: true,
      register_submit_status: true,
      password_show_status: false,
    };
  },
  created() {
    this.getCodeImg();
  },
  methods: {
    // 验证码提交
    codeImgSubmit() {
      if (this.mobile.trim() === '') {
        this.mobile_status_error = true;
        return false;
      }
      if (this.captcha_code.trim() === '') {
        this.mobile_status_error = false;
        this.code_img_status_error = true;
        return false;
      }
      let url = this.$api.verification;
      let data = {
        mobile: this.mobile,
        type: 'forgot',
        captcha_key: this.codeImgInfo.captcha_key,
        captcha_code: this.captcha_code,
      };
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.$message.success('验证码已发送，请注意查收');
            this.codeTextInfo = res.data.data;
            this.code_img_status = false;
            this.time_status = false;
            this.setIntervalFun();
          } else {
            this.getCodeImg();
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.getCodeImg();
          this.$message.error(error.response.data.message);
        });
    },
    // 刷新验证码
    reloadCode: _.debounce(function() {
      this.getCodeImg();
    }, 500),
    // 获取图片验证码
    getCodeImg() {
      let url = this.$api.Captcha;
      this.$http.post(url, true).then((res) => {
        if (res.data.success) {
          this.codeImgInfo = res.data.data;
        }
      });
    },
    // 找回密码
    registerBtn() {
      if (this.verification_code.trim() === '' || this.verification_code.length != 6) {
        this.verification_code_status = true;
        return false;
      }
      // if (this.password.trim() === '' || this.password.length < 6) {
      //   this.verification_code_status = false;
      //   this.password_status = true;
      //   return false;
      // }
      // this.password_status = false;
      this.register_submit_status = false;
      let url = this.$api.resetPassword;
      let data = {
        verification_key: this.codeTextInfo.key,
        verification_code: this.verification_code,
        password: this.password,
        source: 'pc',
      };
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.register_submit_status = true;
            /*this.$cache.setLoginInfo(res.data.data)
          this.$user.getUserInfo()*/
            this.$message.success('修改成功');
            setTimeout(() => {
              this.$router.replace('/login');
            }, 300);
            //this.$router.replace('/')
          } else {
            this.register_submit_status = true;
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.register_submit_status = true;
          this.$message.error(error.response.data.message);
        });
    },
    // 获取验证码
    getCodeText: _.debounce(function() {
      this.updateCode();
    }, 500),
    // 计时器
    setIntervalFun() {
      this.timer = setInterval(() => {
        if (this.time === 0) {
          this.time_status = true;
          this.time = 120;
          //this.codeTextInfo= {}
          clearInterval(this.timer);
          return;
        }
        this.time--;
      }, 1000);
    },
    // 返回修改手机号
    backMobile() {
      this.code_img_status = true;
      this.captcha_code = '';
      if (this.timer != null) {
        clearInterval(this.timer);
      }
      this.time = 120;
      this.time_status = true;
      //this.codeTextInfo= {}
      this.getCodeImg();
    },
    // 更新验证码
    updateCode() {
      let url = this.$api.verification;
      let data = {
        mobile: this.mobile,
        type: 'register',
      };
      this.$http
        .post(url, data, true)
        .then((res) => {
          if (res.data.success) {
            this.time_status = false;
            this.setIntervalFun();
            this.$message.success('验证码已发送，请注意查收');
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
    // 登录
    loginLink() {
      this.$router.push('/login');
    },
    // 密码展示状态
    padShowStatus() {
      this.password_show_status = !this.password_show_status;
    },
    // 首页跳转
    linkHome() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/auth/auth.less';
.register-btn {
  margin-top: 104px !important;
}
</style>
